// import axios from "axios";
// import React from 'react'
// import qs from 'qs'

// const useCataLog = (cate, locationId) => {
//     const [items, setItems] = React.useState([])
//     const [loading, setLoading] = React.useState(false)

//     const getCatalog = async () => {
//         setLoading(true)
//         const catalogitems = await axios({
//             method: 'get',
//             url: 'http://localhost:5000/items',
//             headers: {
//                 'Content-Type': 'application/json'
//             },
//             params: {
//                 category: cate,
//                 locationId: locationId
//             }

//         }).then(function (response) {
//             return response.data
//         }).catch(function (error) {
//             setLoading(false)
//             console.log(error);
//         });


//         console.log('catalogitems', catalogitems);
//         // const filtered = catalogitems.filter(item => item?.category === cate)
//         setItems(catalogitems.data)
//         setLoading(false)

//         return catalogitems
//     }
//     React.useEffect(() => {
//         getCatalog()
//         // console.log('items', items);
//     }, [])
//     return { items: items, setItems, loading: loading, getCatalog, }
// }

// export default useCataLog;


import axios from "axios";
import React from 'react'
import qs from 'qs'

const useCataLog = (cate, locationId) => {
    const [items, setItems] = React.useState([])
    const [loading, setLoading] = React.useState(false)

    const getCatalog = async () => {
        setLoading(true)
        const access_token = localStorage.getItem('access_token')

        await axios({
            method: 'post',
            url: 'https://accounts.iqmetrix.net/v1/oauth2/token',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: qs.stringify({
                'grant_type': 'password',
                'username': 'RunwayPotCustomMenu.COVA.APIUser.Runwaypot',
                'password': '697Mount#Dixon',
                'client_id': 'Runwaypot.RunwayPotCustomMenu',
                'client_secret': 'd8ISCteBlDcomviPVl5LgsfV'
            })
        }).then(function (response) {
            localStorage.setItem('access_token', response.data.access_token)
        }).catch(function (error) {
            setLoading(false)
            console.log(error);
        });

        // const catelog = await axios({
        //     method: 'get',
        //     url: 'https://api.covasoft.net/catalogs/v1/Companies(243559)/Catalog/Items',
        //     headers: {
        //         'Authorization': `Bearer ${access_token}`
        //     }
        // })
        //     .then(function (response) {
        //         return response.data
        //     })
        //     .catch(function (error) {
        //         setLoading(false)
        //         console.log(error);
        //     });

        const quantityList = await axios({
            method: 'get',
            url: `https://api.covasoft.net/availability/v1/Companies(243559)/Entities(${locationId})/CatalogItems/SellingRoomOnly`,
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        }).then(function (response) {
            return response.data
        }).catch(function (error) {
            setLoading(false)
            console.log(error);
        });

        const quantities = quantityList.filter(q => q.Quantity > 0)
        // console.log('quantities', quantities);

        let bulkCatelog = {}
        const bulkCatelogFun = async (first, second) => {
            // console.log('first, second', first, second);
            let catelogIds = []
            await Promise.all(quantities?.slice(first, second)?.map(c => {
                catelogIds.push(c.Id)
            }))

            const data = JSON.stringify({
                "CatalogItemIds": catelogIds
            });

            const config = {
                method: 'post',
                url: 'https://api.covasoft.net/catalogs/v1/Companies(243559)/Catalog/Items/ProductDetails/Bulk',
                headers: {
                    'Authorization': `Bearer ${access_token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            await axios(config)
                .then(function (response) {
                    const items = response.data.CatalogItems
                    bulkCatelog = { ...bulkCatelog, ...items }
                })
                .catch(function (error) {
                    setLoading(false)
                    console.log(error);
                });
        }


        let firstSlice = 0
        let secondSlice = 499

        const allCateItems = async () => {



            if (secondSlice < quantities?.length) {

                await bulkCatelogFun(firstSlice, secondSlice)
                firstSlice += 500
                secondSlice += 500
                await allCateItems()
            } else {

                if (secondSlice !== quantities?.length) {
                    secondSlice = quantities?.length
                    await bulkCatelogFun(firstSlice, secondSlice)

                }
            }
        }

        await allCateItems()



        // console.log('bulkCatelog', bulkCatelog, Object.keys(bulkCatelog).length);
        const pricing = await axios({
            method: 'get',
            url: `https://api.covasoft.net/pricing/v1/Companies(243559)/Entities(${locationId})/CatalogItems`,
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        }).then(function (response) {
            return response.data
        })
            .catch(function (error) {
                setLoading(false)
                console.log(error);
            });





        const catalogitems = await Promise.all(quantities.map(async d => {
            const c = bulkCatelog[d.Id]
            // console.log('pro', c);

            let name = '', brand = '', category = '', thc_min = '', thc_max = '', cbd_min = '', cbd_max = '', weight = '', strain = '', image = '', price = 0, quantity = 0

            if (c) {

                const singlePrice = await pricing.find(p => p.CatalogItemId === d.Id)
                price = singlePrice ? singlePrice.RegularPrice : 0
                c?.Assets.map(a => {
                    image = a['Uri']
                })

                quantity = d.Quantity
                c?.Assets.map(a => {
                    image = a['Uri']
                })

                // console.log('specification', c.Specifications);

                await Promise.all(c?.Specifications.map(s => {
                    s.Fields.map(f => {
                        if (f['DisplayName'] == "Online Menu Name") {
                            name = f['Value'];
                        }
                        if (f['DisplayName'] == "Online Menu Brand") {
                            brand = f['Value'];
                        }
                        if (f['DisplayName'] == "Online Menu Category") {
                            category = f['Value'];
                        }
                        if (f['DisplayName'] == "THC Min") {
                            thc_min = f['Value'];
                        }
                        if (f['DisplayName'] == "THC Max") {
                            thc_max = f['Value'];
                        }
                        if (f['DisplayName'] == "CBD Min") {
                            cbd_min = f['Value'];
                        }
                        if (f['DisplayName'] == "CBD Max") {
                            cbd_max = f['Value'];
                        }
                        if (f['DisplayName'] == "Net Weight" || f['DisplayName'] == "Equivalent To") {
                            weight = f['Value'] + " " + f['Unit'].toUpperCase();
                        }
                        if (f['DisplayName'] == "Strain") {
                            strain = f['Value'];
                        }
                    })
                }))
            }

            return (quantity > 0) && {
                Id: d.Id,
                name: name, brand: brand, category: category, thc_min: thc_min, thc_max: thc_max, thc: `${thc_min ? thc_min + '% - ' : ''}  ${thc_max ? thc_max + '%' : ''}`, cbd_min: cbd_min, cbd_max: cbd_max, cbd: `${cbd_min ? cbd_min + '% - ' : ''}  ${cbd_max ? cbd_max + '%' : ''}`, weight: weight, strain: strain, image: image, price: price, quantity: quantity
            }
        }))






        // const catalogitems = await Promise.all(catelog.map(async d => {
        //     const c = bulkCatelog[d.CatalogItemId]
        //     let name, brand, category, thc_min, thc_max, cbd_min, cbd_max, weight, strain, image, price, quantity

        //     if (c) {

        //         const singlePrice = await pricing.find(p => p.CatalogItemId === d.CatalogItemId)
        //         price = singlePrice.RegularPrice
        //         c?.Assets.map(a => {
        //             image = a['Uri']
        //         })

        //         const singleQuantity = await quantities.find(p => p.Id === d.CatalogItemId)
        //         quantity = singleQuantity ? singleQuantity.Quantity : 0
        //         c?.Assets.map(a => {
        //             image = a['Uri']
        //         })

        //         // console.log('specification', c.Specifications);

        //         await Promise.all(c?.Specifications.map(s => {
        //             s.Fields.map(f => {
        //                 if (f['DisplayName'] == "Online Menu Name") {
        //                     name = f['Value'];
        //                 }
        //                 if (f['DisplayName'] == "Online Menu Brand") {
        //                     brand = f['Value'];
        //                 }
        //                 if (f['DisplayName'] == "Online Menu Category") {
        //                     category = f['Value'];
        //                 }
        //                 if (f['DisplayName'] == "THC Min") {
        //                     thc_min = f['Value'];
        //                 }
        //                 if (f['DisplayName'] == "THC Max") {
        //                     thc_max = f['Value'];
        //                 }
        //                 if (f['DisplayName'] == "CBD Min") {
        //                     cbd_min = f['Value'];
        //                 }
        //                 if (f['DisplayName'] == "CBD Max") {
        //                     cbd_max = f['Value'];
        //                 }
        //                 if (f['DisplayName'] == "Net Weight" || f['DisplayName'] == "Equivalent To") {
        //                     weight = f['Value'] + " " + f['Unit'].toUpperCase();
        //                 }
        //                 if (f['DisplayName'] == "Strain") {
        //                     strain = f['Value'];
        //                 }
        //             })
        //         }))
        //     }

        //     return (quantity > 0) && {
        //         name: name, brand: brand, category: category, thc_min: thc_min, thc_max: thc_max, thc: `${thc_min ? thc_min + '% - ' : ''}  ${thc_max ? thc_max + '%' : ''}`, cbd_min: cbd_min, cbd_max: cbd_max, cbd: `${cbd_min ? cbd_min + '% - ' : ''}  ${cbd_max ? cbd_max + '%' : ''}`, weight: weight, strain: strain, image: image, price: price, quantity: quantity
        //     }
        // }))
        console.log('catalogitems', catalogitems, catalogitems.length);
        const filtered = catalogitems.filter(item => item?.category === cate)
        setItems(cate ? filtered : catalogitems)
        setLoading(false)

        return catalogitems
    }
    React.useEffect(() => {
        getCatalog()
        // console.log('items', items);
    }, [])
    return { items: items, setItems, loading: loading, getCatalog }
}

export default useCataLog;