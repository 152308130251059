import './App.css';
import { ThemeProvider } from "@mui/material";
import Routes from "./routes";
import theme from './constants/theme'

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Routes />
      </ThemeProvider>
    </div>
  );
}

export default App;
