import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Container, Stack, Button, Grid, CircularProgress, Backdrop } from "@mui/material";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import categories from '../categories.json'

const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.gray[300],
        height: '100%',
        minHeight: '100vh'
    },
    box: {
        padding: theme.spacing(5, 0),
    },

}));





const Categories = (props) => {
    const { locationId } = useParams()
    // const [loading, setLoading] = React.useState(false)
    // const [categories, setCategories] = React.useState([])
    // const getCategories = async () => {
    //     const result = await axios({
    //         method: 'get',
    //         url: 'http://localhost:5000/categories',
    //         headers: {
    //             'Content-Type': 'application/json'
    //         },

    //     }).then(function (response) {
    //         setLoading(false)
    //         return response.data
    //     }).catch(function (error) {
    //         console.log(error);
    //     });
    //     setCategories(result.data)
    //     setLoading(false)
    // }
    const classes = useStyles();
    const history = useHistory()
    // React.useEffect(() => {
    //     getCategories()
    // }, [locationId])
    return (
        <>
            {/* <Backdrop
                sx={{ color: 'white', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop> */}
            <div className={classes.root}>
                <Container maxWidth="lg" >
                    <Box className={classes.box} >
                        <Grid container spacing={2}>

                            {categories?.map((item, index) => {
                                return item && (
                                    <Grid item lg={3}>
                                        <Button variant="contained" fullWidth onClick={() => history.push(`/items/${item}/${locationId}`)}>{item}</Button>
                                    </Grid>
                                )
                            })}
                        </Grid>

                    </Box>
                </Container>
            </div>
        </>
    );
};

export default Categories;
