import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Container, Stack, Button, Grid, CircularProgress, Backdrop } from "@mui/material";
import useCataLog from "../hooks/useCatalog";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import qs from 'qs'

const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.gray[300],
        height: '100%',
        minHeight: '100vh'
    },
    box: {
        padding: theme.spacing(5, 0),
    },

}));





const Locations = (props) => {
    const [loading, setLoading] = React.useState(false)
    const [locations, setLocations] = React.useState([])

    const access_token = localStorage.getItem('access_token')
    const token = async () => {
        await axios({
            method: 'post',
            url: 'https://accounts.iqmetrix.net/v1/oauth2/token',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: qs.stringify({
                'grant_type': 'password',
                'username': 'RunwayPotCustomMenu.COVA.APIUser.Runwaypot',
                'password': '697Mount#Dixon',
                'client_id': 'Runwaypot.RunwayPotCustomMenu',
                'client_secret': 'd8ISCteBlDcomviPVl5LgsfV'
            })
        }).then(function (response) {
            localStorage.setItem('access_token', response.data.access_token)
        }).catch(function (error) {
            setLoading(false)
            console.log(error);
        });

    }

    const getLocation = async () => {
        setLoading(true)
        const locations = await axios({
            method: 'get',
            url: 'https://entitymanager.iqmetrix.net/v1/Companies(243559)/Tree?roleDepth=Location',
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        })
            .then(function (response) {
                return response.data["Nodes"]
            })
            .catch(function (error) {
                setLoading(false)
                console.log(error);
            });
        setLocations(locations)
        setLoading(false)
    }
    const classes = useStyles();
    const history = useHistory()
    React.useEffect(() => {
        token()
        getLocation()
    }, [])
    return (
        <>
            <Backdrop
                sx={{ color: 'white', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className={classes.root}>
                <Container maxWidth="lg" >
                    <Box className={classes.box} >
                        <Grid container spacing={2}>

                            {locations?.map((item, index) => {
                                return item && (
                                    <Grid item lg={3}>
                                        <Button variant="contained" fullWidth onClick={() => history.push(`/categories/${item.Id}`)}>{item.Name}</Button>
                                    </Grid>
                                )
                            })}
                        </Grid>

                    </Box>
                </Container>
            </div>
        </>
    );
};

export default Locations;
