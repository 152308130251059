import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Card, CardContent, Grid, Typography, Stack, CircularProgress, Backdrop } from "@mui/material";
import useCataLog from "../hooks/useCatalog";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.gray[700],
        padding: theme.spacing(2),
        height: '100%',
        minHeight: '100vh'
    },
    card: {
        position: 'relative',
        height: 350,
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        border: `3px solid #e6b659`,
    },

    title: {
        color: theme.palette.secondary.main
    },
    img: {
        width: '100%',
        height: 130,
        objectFit: 'contain',

    },
    boxContent: {
        // background: '#e1e1e1',
        borderTop: `3px solid #e1e1e1`,
        // borderRadius: theme.spacing(1),
        // padding: theme.spacing(2)
        paddingTop: theme.spacing(2)
    },
    weightBox: {
        position: 'absolute',
        top: 20,
        left: 20,
        padding: theme.spacing(0.2, 0.7),
        borderRadius: theme.spacing(0.5),
    },
    weight: {
        textAlign: 'center',
        color: theme.palette.text.primary,
    },

    priceBox: {
        position: 'absolute',
        bottom: -10,
        right: 20,
        padding: theme.spacing(0.2, 0.7),
        borderRadius: theme.spacing(0.5),
    },
    price: {
        textAlign: 'center',
        color: theme.palette.secondary.main,
    },
    cardContent: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        height: '100%',
    },
    productName: {
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 2,
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    brandName: {
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 2,
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    imgContainer: {
        display: 'flex', height: '100%', position: 'relative', justifyContent: 'center', alignItems: 'center',
        padding: theme.spacing(1.5)
    }
}));




const CardList = ({ name: title, image, brand, thc, cbd, weight, price, quantity }) => {
    const classes = useStyles()
    return (
        <Card elevation={0} className={classes.card}>
            <Box className={classes.imgContainer}>
                <img
                    src={image}
                    alt={title}
                    loading="lazy"
                    className={classes.img}
                />
                {price && <Box className={classes.priceBox}>
                    <Typography variant="h3" className={classes.price}>
                        $ {price}
                    </Typography>
                </Box>}
            </Box>
            {weight && <Box className={classes.weightBox}>
                <Typography variant="h4" className={classes.weight}>
                    {weight}
                </Typography>
            </Box>}
            <CardContent className={classes.cardContent}>
                <Box sx={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <Typography gutterBottom variant="h4" textAlign="center" className={classes.productName}>
                        {title?.split('-')[1]}
                        {/* {title} */}
                    </Typography>
                    <Typography variant="h5" color="secondary" className={classes.brandName}>({brand})</Typography>
                </Box>
                <Box className={classes.boxContent}>
                    {/* <Typography variant="body2" color="primary" textAlign="center">
                        Brand : {brand}
                    </Typography>

                    <Divider sx={{ marginBottom: 1.5, marginTop: 1.5 }} /> */}
                    <Stack direction="row" justifyContent='space-evenly' alignItems="center">
                        <Stack direction="column" justifyContent='space-between' alignItems="center">
                            <Typography variant="h5" color="secondary">
                                THC
                            </Typography>
                            <Typography variant="h5">
                                {thc}
                            </Typography>
                        </Stack>
                        <Stack direction="column" justifyContent='space-between' alignItems="center">
                            <Typography variant="h5" color="secondary">
                                CBD
                            </Typography>
                            <Typography variant="h5">
                                {cbd}
                            </Typography>
                        </Stack>
                    </Stack>
                </Box>
            </CardContent>
        </Card >
    )
}


const Home = (props) => {
    const { cate, locationId } = useParams()
    const { items, loading, getCatalog } = useCataLog(cate, locationId)
    const rowsPerPage = 9
    const [localPage, setLocalPage] = React.useState(0);
    console.log('items', items?.length);
    setTimeout(() => {
        if (!loading) {
            if (items.length >= rowsPerPage) {
                const totalPages = items.length / rowsPerPage;
                // console.log(
                //     "totalPages",
                //     items.length,
                //     localPage * rowsPerPage,
                //     localPage * rowsPerPage + rowsPerPage,
                //     items?.slice(
                //         localPage * rowsPerPage,
                //         localPage * rowsPerPage + rowsPerPage
                //     ).length
                // );
                if (localPage === Math.ceil(totalPages) - 1) {
                    setLocalPage(0);
                } else {
                    setLocalPage(localPage + 1);
                }
                // setLocalPage(page + 1)
            }
        }
    }, 15000);
    React.useEffect(() => {
        setInterval(async () => {
            if (!loading) {
                // console.log('getCatalog', loading);
                await getCatalog()
            }
        }, 60000 * 5);
    }, []);


    const classes = useStyles();
    return (
        <>
            <Backdrop
                sx={{ color: 'white', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={items?.length <= 0}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className={classes.root}>
                {/* <Container maxWidth="xl" > */}
                <Box className={classes.box} >
                    <Typography variant="h1" className={classes.title} fontWeight={600} pb={5} textAlign="center">{cate}</Typography>
                    <Grid container spacing={2}>
                        {items?.slice(
                            localPage * rowsPerPage,
                            localPage * rowsPerPage + rowsPerPage
                        )?.map((item, index) => {
                            return (
                                <Grid item lg={4} sm={4} md={4} xs={4} key={index}>
                                    <CardList {...item} />
                                </Grid>
                            )
                        })}
                    </Grid>
                </Box>
                {/* </Container> */}
            </div>
        </>
    );
};

export default Home;
