import { createTheme } from "@mui/material/styles";

let theme = createTheme({
    palette: {
        // type: "dark",
        white: '#fff',
        black: '#000',
        background: {
            lighter: "rgba(0,0,0,0.6)",
            main: "#363535",
            light: '#EBF8F2',
            dark: "#212121",
            // paper: "#3B3B3B",
        },
        text: {
            primary: "#212b36",
            inverted: "#637381",
        },
        gray: {
            100: '#f9f9f9',
            200: '#F7F7F7',
            300: '#f4f4f4',
            400: '#F3F3F3',
            500: '#f1f1f1', // border alt color
            600: '#EdEdEd',
            700: '#E6E6E6', // border color
            800: '#C2C3CC',
            900: '#bdbdbd',
            1000: '#ababab',
            1200: '#919191',
        },
        // primary: {
        //   main: "#1f5f61",
        // },
        primary: {
            main: "#4e3299",
            light: '#EBF8F2',
        },
        secondary: {
            main: "#db652a",
            light: '#E5F3FF'
        },
        success: {
            main: "#229a16",
            light: '#54d62c30',
        },
        error: {
            main: "#b72136",
            light: '#ff484230',
        },
        warning: {
            main: "#b78103",
            light: '#fff7cd',
        },
        fontWeights: {
            thin: 100,
            light: 300,
            regular: 400,
            medium: 500,
            semiBold: 600,
            bold: 700,
            bolder: 900,
        },
        radius: {
            base: '4px',
            small: '3px',
            medium: '8px',
            big: '20px',
            bigger: '50px'
        },
        fontSizes: {
            xs: 11,
            sm: 13,
            base: 15,
            semibase: 17,
            md: 19,
            lg: 21,
            xl: 24,
            "2xl": 30,
            "3xl": 36,
            "4xl": 42,
            "5xl": 48,
            "6xl": 54,
            "7xl": 60,
        },
        // blue: {
        //   main: blue[100],
        // },

        // green: {
        //   main: green,
        // },
    },
    typography: {
        fontFamily: ["Poppins"].join(","),
        subtitle1: {
            fontWeight: 600
        },
        h3: {
            fontSize: 42,
            fontWeight: 700
        },
        h5: {
            fontWeight: 700
        },
        h4: {
            fontWeight: 600
        },
        body1: {
            fontWeight: 700
        },
        body2: {
            fontWeight: 600
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
});

theme = createTheme(theme, {
    components: {
        MuiTypography: {
            styleOverrides: {
                h5: {
                    [theme.breakpoints.up('xs')]: {
                        fontSize: theme.palette.fontSizes.sm
                    },
                    [theme.breakpoints.up('sm')]: {
                        fontSize: theme.palette.fontSizes.base
                    },
                    [theme.breakpoints.up('md')]: {
                        fontSize: theme.palette.fontSizes.semibase
                    },
                    [theme.breakpoints.up('lg')]: {
                        fontSize: theme.palette.fontSizes.md
                    },
                },
                h4: {
                    [theme.breakpoints.up('xs')]: {
                        fontSize: theme.palette.fontSizes.base
                    },
                    [theme.breakpoints.up('sm')]: {
                        fontSize: theme.palette.fontSizes.semibase
                    },
                    [theme.breakpoints.up('md')]: {
                        fontSize: theme.palette.fontSizes.md
                    },
                    [theme.breakpoints.up('lg')]: {
                        fontSize: theme.palette.fontSizes.lg
                    },
                },
                h3: {
                    [theme.breakpoints.up('xs')]: {
                        fontSize: theme.palette.fontSizes.md
                    },
                    [theme.breakpoints.up('sm')]: {
                        fontSize: theme.palette.fontSizes.lg
                    },
                    [theme.breakpoints.up('md')]: {
                        fontSize: theme.palette.fontSizes.xl
                    },
                    [theme.breakpoints.up('lg')]: {
                        fontSize: theme.palette.fontSizes["2xl"]
                    },
                },
                h2: {
                    [theme.breakpoints.up('xs')]: {
                        fontSize: theme.palette.fontSizes.md
                    },
                    [theme.breakpoints.up('sm')]: {
                        fontSize: theme.palette.fontSizes.lg
                    },
                    [theme.breakpoints.up('md')]: {
                        fontSize: theme.palette.fontSizes.xl
                    },
                    [theme.breakpoints.up('lg')]: {
                        fontSize: theme.palette.fontSizes["2xl"]
                    },
                },
                h1: {
                    [theme.breakpoints.up('xs')]: {
                        fontSize: theme.palette.fontSizes['2xl']
                    },
                    [theme.breakpoints.up('sm')]: {
                        fontSize: theme.palette.fontSizes['3xl']
                    },
                    [theme.breakpoints.up('md')]: {
                        fontSize: theme.palette.fontSizes["4xl"]
                    },
                    [theme.breakpoints.up('lg')]: {
                        fontSize: theme.palette.fontSizes["5xl"]
                    },
                }
            },
        },
    },
});

export default theme