import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Route } from 'react-router-dom'
import Categories from '../pages/categories'
import Home from '../pages/home'
import Locations from '../pages/locations'

const index = () => {
    return (
        <>
            <BrowserRouter>
                <Route path="/" exact component={Locations} />
                <Route path="/categories/:locationId" exact component={Categories} />
                <Route path="/items/:cate/:locationId" exact component={Home} />
            </BrowserRouter>
        </>
    )
}

export default index
